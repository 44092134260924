nav {
  margin-bottom: 1rem;
  background: #2185d0;
}

.ui.menu.squared {
  border-radius: 0;
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
}

.ui.menu .header span {
  margin-left: 1rem;
}

.ui.search.menusearch>.results {
  width: 14.5em;
}