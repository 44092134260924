footer {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: #2185d0;
  color: rgba(255,255,255,.9);
  font-size: 10pt;
}

footer a, footer a:visited {
  color: rgba(255,255,255,.9);
}

footer a:hover, footer button.link:hover {
  color: rgba(255,255,255,1);
}
