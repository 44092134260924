body {
  background-color: #fff;
  background-image: url(/img/gplaypattern.png);
}

body.darkMode {
  background-color: #000;
  background-image: url(/img/gplaypattern-inverse.png);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root main {
  flex: 1 1 auto;
}

button.link {
  background: none!important;
  color: inherit;
  border: none;
  padding: 0!important;
  font: inherit;
  cursor: pointer;
}

body.hideRunTimes .runTime {
  background-color: #000;
}

body.hideRunTimes.darkMode .runTime {
  background-color: #fff;
}

body.hideRunTimes .runTime:hover {
  background-color: initial;
}

body.hideRunTimes.darkMode .runTime:hover {
  background-color: initial;
}